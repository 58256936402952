const ImageFileList = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/ListPage.vue");
const ImageFileDetail = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/FormPage.vue");
const ImageFileIndex = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/IndexPage.vue");

export default [
  {
    path: "/image_file",
    component: ImageFileIndex,
    redirect: "/image_file/list",
    name: "image_file",
    children: [
      {
        path: "list",
        component: ImageFileList,
        name: "image_file.list",
      },
      {
        path: "create",
        component: ImageFileDetail,
        name: "image_file.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: ImageFileDetail,
        name: "image_file.edit",
        props: { create: false },
      },
    ],
  },
];
