<template>
    <span>
        <div class="row mb-2">
            <div class="col">
                <slot name="left"></slot>
                <BNavForm>
                    <BFormInput
                        v-model="listOptions.q"
                        class="mr-2"
                        placeholder="Search"
                        type="search"
                        @keydown.enter.native.prevent="
                            update_table(listOptions)
                        "
                    ></BFormInput>
                    <BFormSelect
                        v-if="!hideSearchFieldsList"
                        v-model="listOptions.searchField"
                        :options="searchFieldsList"
                        class="mr-2 mpl-2"
                    />

                    <BButton
                        v-b-tooltip
                        class="ml-0 my-2"
                        title="Search"
                        @click="update_table(listOptions)"
                        ><filter-icon
                    /></BButton>
                    <BButton
                        v-if="showClear"
                        id="clear_search"
                        v-b-tooltip
                        class="my-2 my-sm-0"
                        title="Clear Search"
                        variant="danger"
                        @click="reset_table()"
                        ><filter-remove-outline-icon
                    /></BButton>

                    <BButton
                        v-if="allow_query && !hideAdvancedQuery"
                        id="advanced_query"
                        v-b-tooltip
                        :variant="filter_btn_variant"
                        class="my-2 my-sm-0"
                        title="Advanced Query"
                        @click="toggle_query()"
                        ><database-search-icon></database-search-icon
                    ></BButton>

                    <span v-if="downloadOptions" class="mx-2">
                        <BDropdown class="m-md-2" text="Download">
                            <BDropdownItem
                                v-for="download in downloadOptionsWithData"
                                :key="download.uri"
                                class="download"
                            >
                                <o-download-file-button
                                    :filename="download.filename"
                                    :request="listOptions"
                                    :uri="download.uri"
                                    class="font-weight-bold text-decoration-none"
                                    method="get"
                                    variant="link"
                                >
                                    {{ download.format_name }}
                                </o-download-file-button>
                            </BDropdownItem>
                        </BDropdown>
                    </span>

                    <BButton
                        v-if="codeTable"
                        id="codeTableCreate"
                        v-b-tooltip
                        variant="dark"
                        class="my-2 my-sm-0"
                        title="Create Entries"
                        v-b-modal.modal-create-codes
                    >
                        <file-table-box-multiple-outline-icon></file-table-box-multiple-outline-icon>
                    </BButton>

                    <BButton
                        v-if="newTo"
                        :to="newTo"
                        class="my-2 ml-2"
                        tag="router-link"
                        variant="primary"
                    >
                        {{ newButtonText }}
                    </BButton>
                    <slot name="middle"></slot>
                </BNavForm>
            </div>
            <div :class="filterClasses">
                <slot name="right"></slot>
                <BFormSelect
                    :options="filtersList"
                    :value="selectedFilter"
                    class="mr-2 col-xs-12 mt-2 w-lg-25 w-lg-50 float-md-right float-lg-right filter-select w-100"
                    options-field="None"
                    text-field="name"
                    value-field="code"
                    @input="update_filter"
                />
            </div>
        </div>

        <div v-if="(showing_query && allow_query) || alwaysShowQuery">
            <query-builder
                :meta-data="schema"
                :query-select-options="querySelectOptions"
                :value="the_query"
                @change="query_changed"
            ></query-builder>
        </div>

        <BModal
            id="modal-create-codes"
            title="Create Entries"
            @ok="createEntries"
            @cancel="closeEntries"
            header-bg-variant="light"
            footer-bg-variant="light"
        >
            <p>
                Create new entries but putting a line for each new entry. <br />
                To enter the code put in code=description
            </p>
            <BFormGroup label="Entries">
                <BFormTextarea
                    v-model="entries"
                    rows="10"
                    placeholder="code=description or description"
                ></BFormTextarea>
            </BFormGroup>
        </BModal>
    </span>
</template>
<script>
import {
    BButton,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BModal,
    BNavForm,
} from "bootstrap-vue";
import _ from "lodash";
import FilterIcon from "vue-material-design-icons/Filter.vue";
import FilterRemoveOutlineIcon from "vue-material-design-icons/FilterRemoveOutline.vue";
import DatabaseSearchIcon from "vue-material-design-icons/DatabaseSearch.vue";
import FileTableBoxMultipleOutlineIcon from "vue-material-design-icons/FileTableBoxMultipleOutline.vue";
import QueryBuilder from "../../query/component/QueryBuilder.vue";
import { normaliseOptions } from "../../store/store_utils.js";

export default {
    name: "OListToolbar",
    components: {
        BNavForm,
        BFormInput,
        BFormSelect,
        BButton,
        BDropdown,
        BDropdownItem,
        BModal,
        BFormGroup,
        BFormTextarea,
        QueryBuilder,
        DatabaseSearchIcon,
        FilterRemoveOutlineIcon,
        FilterIcon,
        FileTableBoxMultipleOutlineIcon,
    },
    data() {
        return {
            show_query: false,
            entries: "",
        };
    },
    props: {
        filtersList: {},
        searchFieldsList: {},
        listOptions: {},
        selectedFilter: {},

        newButtonText: { type: String, default: "Create New" },
        newTo: { type: [Object, String], default: null },

        schema: { type: Object, default: null },
        query: { type: Object, default: null },
        alwaysShowQuery: { type: Boolean, default: false },
        querySelectOptions: {
            type: Object,
            default: () => {},
        },

        downloadOptions: { type: Array, default: null },

        codeTable: { type: Boolean, default: false },
        filterClasses: { type: String, default: "col-xs-12 col-md-2 col-lg-2" },
        hideSearchFieldsList: { type: Boolean, default: false },
        hideAdvancedQuery: { type: Boolean, default: false },
    },
    computed: {
        showClear() {
            return this.listOptions.q || this.listOptions.searchField;
        },
        allow_query() {
            return this.schema != null;
        },
        the_query() {
            var result = { and: [[null, null]] };
            if (this.listOptions && this.listOptions.query)
                result = this.listOptions.query;

            return result;
        },
        filter_btn_variant() {
            if (this.showing_query) {
                return "info";
            } else {
                return "secondary";
            }
        },
        showing_query() {
            if (this.listOptions) {
                if (this.listOptions.query) {
                    return true;
                }
            }

            return this.show_query;
        },

        downloadOptionsWithData() {
            var params = normaliseOptions(this.listOptions);
            params.query = JSON.stringify(params.query);
            params.qbe = JSON.stringify(params.qbe);
            params.limit = 65535;
            params.offset = 0;

            return _.map(this.downloadOptions, function (option) {
                var result = { ...option };

                params.columns = JSON.stringify(option.fields);

                var get_params = Object.entries(params)
                    .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
                    .join("&");
                result.uri = result.uri + "?" + get_params;

                return result;
            });
        },
    },
    methods: {
        reset_table() {
            this.$emit("reset_table");
        },
        update_table(listOptions) {
            this.$emit("options_changed", listOptions);
        },
        update_filter(value) {
            this.$emit("filter_changed", value);
        },
        toggle_query() {
            if (this.showing_query === true) {
                this.query_changed(null);
            }

            this.show_query = !this.show_query;
        },
        query_changed(query) {
            this.listOptions.query = query;

            this.$emit("options_changed", this.listOptions);
        },
        closeEntries() {
            this.entries = "";
        },
        createEntries() {
            var lines = this.entries.split("\n");

            const vm = this;

            _.each(lines, function (line) {
                if (line != "") {
                    var row = { description: line, code: "" };

                    if (line.includes("=")) {
                        var rowSplit = line.split("=");

                        row = { description: rowSplit[1], code: rowSplit[0] };
                    }

                    vm.$emit("create", row);
                }
            });

            this.entries = ""; // reset
            vm.update_table(vm.list_options);
        },
    },
};
</script>

<style lang="scss">
.download {
    .dropdown-item {
        padding: 0 !important;
    }
}

.filter-select {
    min-width: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1080px) {
    .w-lg-50 {
        width: 50% !important;
    }
}

@media only screen and (min-width: 1200px) {
    .w-lg-25 {
        width: 25% !important;
    }
}
</style>
