import _ from "lodash";
import { mapGetters } from "vuex";
import {
    canReadField,
    canUpdateField,
    updateSchemaModelWithPermissions,
} from "../grouppermissions/utils.js";

/* eslint-disable no-unused-vars */
export default function (modelname) {
    // NOTE: this.$router.currentRoute is the current route!
    return {
        methods: {
            canSee(schema, fieldName) {
                return canReadField(
                    this._group_permissions,
                    this.$router.currentRoute.name,
                    schema.name,
                    fieldName
                );
            },
            canUpdate(schema, fieldName) {
                return canUpdateField(
                    this._group_permissions,
                    this.$router.currentRoute.name,
                    schema.name,
                    fieldName
                );
            },

            filterTableColumns(schema, columnList) {
                var vm = this;

                if (!this._use_permissions) {
                    return columnList;
                }

                var result = [];

                _.forEach(columnList, function (column) {
                    if (column != null) {
                        // it null don't add it in
                        var fieldName = column.key;

                        if (
                            canReadField(
                                vm._group_permissions,
                                vm.$router.currentRoute.name,
                                schema.name,
                                fieldName
                            )
                        ) {
                            result.push(column);
                        }
                    }
                });

                return result;
            },
        },
        computed: {
            ...mapGetters({
                _group_permissions: "AuthModule/permissions",
                _use_permissions: "AuthModule/use_permissions",
            }),
            schema() {
                if (!this.schemaModel) {
                    console.warn(
                        "No schema found! " + this.$options._componentTag
                    );
                }

                return updateSchemaModelWithPermissions(
                    this._group_permissions,
                    this.schemaModel,
                    this.$router.currentRoute.name
                );
            },
        },
    };
}
