<template>
    <o-form-group
        :form-state="formState"
        :label="label"
        :message="errorMessages"
        :nolabel="nolabel"
        :type="{ 'is-danger': hasError }"
    >
        <BInputGroup
            :append="append"
            :prepend="prepend"
            v-if="hasAppend || hasPrepend"
        >
            <template v-slot:append>
                <slot name="append"
                    ><BInputGroupText v-if="append">{{
                        append
                    }}</BInputGroupText></slot
                >
            </template>
            <template v-slot:prepend>
                <slot name="prepend"
                    ><BInputGroupText v-if="prepend">
                        {{ prepend }}
                    </BInputGroupText></slot
                >
            </template>
            <BFormInput
                :class="this_input_class"
                :readonly="_is_readonly"
                type="number"
                v-bind="$attrs"
                v-bind:value="value"
                v-on="inputListeners"
            >
                <slot />
            </BFormInput>
        </BInputGroup>

        <BFormInput
            :class="this_input_class"
            :readonly="_is_readonly"
            type="number"
            v-bind="$attrs"
            v-bind:value="value"
            v-else
            v-on="inputListeners"
        >
            <slot />
        </BFormInput>

        <span v-if="formState.debug">
            SE:{{ serverErrors }} [{{ serverErrorsIndex }}].{{ name }}<br />
            AL_SE:{{ all_server_errors }}
        </span>
    </o-form-group>
</template>

<script>
import { BFormInput, BInputGroup, BInputGroupText } from "bootstrap-vue";
import FormComponentMixin from "../../../mixins/form_component_mixin.js";
import OFormGroup from "../OFormGroup.vue";
import { mergeCss } from "vue-ocom/utils/css";

export default {
    name: "ONumberField",
    components: {
        BInputGroup,
        BInputGroupText,
        BFormInput,
        OFormGroup,
    },
    mixins: [FormComponentMixin],
    props: {
        prepend: { type: String, default: null },
        append: { type: String, default: null },
        emptyValue: { default: null },
        alignLeft: { type: Boolean, default: false },
    },
    methods: {
        update_value(value) {
            if (value === "") {
                value = this.emptyValue;
            } else {
                value = Number(value);
            }
            this.on_change(value);
        },
    },
    computed: {
        this_input_class() {
            if (this.alignLeft) {
                return mergeCss(this.input_class, { "text-left": true });
            } else {
                return mergeCss(this.input_class, { "text-right": true });
            }
        },
        hasPrepend() {
            return this.prepend || !!this.$slots["prepend"];
        },
        hasAppend() {
            return this.append || !!this.$slots["append"];
        },
        inputListeners: function () {
            //overwrites the mixin!

            /*
                    used with v-on="inputListeners" to add our own listeners

                    Listen for input, change or blur to make it dirty/touched
                 */

            var vm = this;
            // `Object.assign` merges objects together to form a new object
            return Object.assign(
                {},
                // We add all the listeners from the parent
                this.$listeners,
                // Then we can add custom listeners or override the
                // behavior of some listeners.
                {
                    // This ensures that the component works with v-model
                    input: function (event) {
                        vm.update_value(event);
                    },
                    // This ensures that the component works with v-model
                    change: function (event) {
                        vm.update_value(event);
                    },
                    blur: function (event) {
                        vm.on_blur(event);
                    },
                }
            );
        },
    },
};
</script>

<!--<style scoped>-->
<!--input[type="number"] {-->
<!--    text-align: right;-->
<!--}-->
<!--</style>-->
