/* eslint-disable no-unused-vars */
import _ from "lodash";
import { mergeCss } from "../utils/css.js";

export default {
    inheritAttrs: false,
    data() {
        return {
            disableServerErrors: false,
            touched: false,
            dirty: false,
            changed: false,

            blurChanged: false,
            changedValue: null
        };
    },
    props: {
        label: { type: String, default: null },
        nolabel: { type: Boolean, default: false },
        value: { type: [String, Number, Boolean, Array, Date, Object] },
        formState: { type: Object },
        validators: {
            type: Object,
            default: function () {
                return null;
            },
        },
        name: { type: String, required: true },
        serverErrors: { type: [Object, Array], default: null },
        serverErrorsIndex: {
            type: Number,
            default: null,
        } /* If Null then serverErrors is not an array */,

        readonly: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        ignoreFormStateLocked: {
            type: Boolean,
            default: false,
        } /* When True will not make readonly on locked forms */,

        className: {
            type: [Array, String, Object],
            default: () => {},
        } /* Use this to set the class of a input */,
        updateOnBlur: { type: Boolean, default: false }
    },
    methods: {
        on_recalc() {
            this.$emit("recalc");
        },
        makeDirty() {
            this.dirty = true;
            if (this.formState) this.formState.dirty = true;
        },
        raiseChangeEvents(value) {
            this.$emit("reset");
            this.$emit("input", value); // Emit input so v-model props works!
            this.$emit("recalc"); // cause Recalc
        },
        on_change(event) {
            // console.log('event change');
            this.makeDirty();
            if (this.validator) {
                this.validator.$touch();
            }

            if (!this.updateOnBlur) {
                this.changed = true;
                this.raiseChangeEvents(event);
            } else {
                this.blurChanged = true;
                this.changedValue = event;
            }
        },
        on_input(event) {
            // console.log('event input');
            this.makeDirty();
            if (this.validator) {
                this.validator.$touch();
            }

            this.changed = true;
            if (!this.updateOnBlur) {
                this.raiseChangeEvents(event);
            } else {
                this.blurChanged = true;
                this.changedValue = event;
            }
        },
        on_blur(event) {
            if (this.changed) {
                this.on_recalc();
            }

            this.touched = true;
            this.changed = true;
            if (this.updateOnBlur && this.blurChanged == true) {
                this.blurChanged = false;
                this.raiseChangeEvents(this.changedValue);
            }
            this.$emit("reset");
            this.$emit("blur", event);
        },
    },
    computed: {
        validator() {
            // Get the Validator or an Empty object.

            if (this.validators && this.name && this.validators[this.name]) {
                return this.validators[this.name];
            }

            return null;
        },
        hasError() {
            // Are there any Errors?
            if (this.all_server_errors) {
                return true;
            }

            if (this.validators && this.name && this.validators[this.name]) {
                return this.validators[this.name].$error;
            }
        },
        all_server_errors() {
            // Get the Server Errors for this Field
            var theErrors = this.serverErrors;

            if (theErrors) {
                if (this.serverErrorsIndex != null && _.isArray(theErrors)) {
                    // We are in Child list.
                    theErrors = theErrors[this.serverErrorsIndex];
                }

                if (theErrors)
                    // there is a value so get the fields errors
                    // if (theErrors[this.name]) {
                    //     console.log ('Got some errors:' + theErrors[this.name]);
                    // }

                    return theErrors[this.name];
            }

            // No errors
            return null;
        },
        errorMessages() {
            // Create a Array of all the errors from serverErrors and validator
            var results = [];

            _.each(this.all_server_errors, function (err) {
                results.push(err);
            });

            // console.log("Server Errors:", results);
            if (this.validator) {
                _.each(this.validator, function (val, key) {
                    if (val === false) {
                        if (!key.startsWith("$")) {
                            // TODO Create Proper Error Messages

                            results.push(key);
                        }
                    }
                });
            }

            return results;
        },
        input_class: function () {
            var calculatedClasses = {
                fieldDirty: this.dirty,
                fieldTouched: this.touched,
                fieldError: this.hasError,
            };

            return mergeCss(this.className, calculatedClasses);
        },
        inputListeners: function () {
            /*
                used with v-on="inputListeners" to add our own listeners

                Listen for input, change or blur to make it dirty/touched
             */

            var vm = this;
            // `Object.assign` merges objects together to form a new object
            return Object.assign(
                {},
                // We add all the listeners from the parent
                this.$listeners,
                // Then we can add custom listeners or override the
                // behavior of some listeners.
                {
                    // This ensures that the component works with v-model
                    input: function (event) {
                        vm.on_input(event);
                    },
                    // This ensures that the component works with v-model
                    change: function (event) {
                        vm.on_change(event);
                    },
                    blur: function (event) {
                        vm.on_blur(event);
                    },
                }
            );
        },
        _is_readonly() {
            var result = false;

            if (this.formState && !this.ignoreFormStateLocked) {
                if ("locked" in this.formState) {
                    // use formState.locked if it exists
                    result = this.formState.locked;
                }
            }

            return result || this.readonly === true || this.disabled === true; // finally honour the props
        },
    },
};
