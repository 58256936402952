const ImageTagList = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/ListPage.vue");
const ImageTagDetail = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/FormPage.vue");
const ImageTagIndex = () =>
  import(/* webpackChunkName: "main_app" */ "./pages/IndexPage.vue");

export default [
  {
    path: "/image_tag",
    component: ImageTagIndex,
    redirect: "/image_tag/list",
    name: "image_tag",
    children: [
      {
        path: "list",
        component: ImageTagList,
        name: "image_tag.list",
      },
      {
        path: "create",
        component: ImageTagDetail,
        name: "image_tag.create",
        props: { create: true },
      },
      {
        path: "edit/:id",
        component: ImageTagDetail,
        name: "image_tag.edit",
        props: { create: false },
      },
    ],
  },
];
