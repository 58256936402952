<template>
    <div>
        <BNavbar fixed="top" toggleable="lg" type="dark" variant="dark">
            <BNavbarBrand :to="{ name: 'home' }">
                <slot name="brand"></slot>
            </BNavbarBrand>

            <BNavbarToggle target="nav-collapse">
                <template #default="{ expanded }">
                    <span v-if="!expanded" class="navbar-toggler-icon"></span>
                    <span v-else class="my-1 mx-2 close text-white">X</span>
                </template>
            </BNavbarToggle>

            <BCollapse id="nav-collapse" is-nav>
                <slot name="left-hand-side"></slot>

                <BNavbarNav>
                    <BNavItem
                        :to="{ name: 'home' }"
                        v-show="isLoggedIn"
                        v-if="!hideHome"
                    >
                        <home-icon></home-icon>
                    </BNavItem>

                    <template v-if="!hideTopItems">
                        <BNavItemDropdown
                            v-if="showNavItem(menu_item)"
                            :key="index"
                            :text="menu_item.name"
                            v-for="(
                                menu_item, index
                            ) in first_section_menu_options"
                            v-show="menu_item.loggedIn === isLoggedIn"
                        >
                            <BDropdownItem
                                :key="childIndex"
                                :to="child.to"
                                v-for="(
                                    child, childIndex
                                ) in menu_item.children"
                                v-show="child.loggedIn === isLoggedIn"
                                >{{ child.title }}
                            </BDropdownItem>
                        </BNavItemDropdown>
                    </template>

                    <slot name="middle-side">
                        <BNavItem
                            :key="index"
                            v-for="(item, index) in toolbar"
                            href="#"
                        >
                            <div v-if="toolbar" v-show="isLoggedIn">
                                <BButton
                                    block
                                    type="button"
                                    :style="toolbarButtonStyle"
                                    class="btn btn-secondary"
                                    @click="onClick(item.URL)"
                                    variant="dark"
                                    squared
                                >
                                    <component
                                        :is="item.icon + '-icon'"
                                    ></component>
                                    {{ item.text }}
                                </BButton>
                            </div>
                        </BNavItem>
                    </slot>
                </BNavbarNav>

                <!-- Right aligned nav items -->
                <BNavbarNav class="ml-auto">
                    <BNavItemDropdown
                        right
                        v-if="currentUser"
                        v-show="isLoggedIn"
                    >
                        <!-- Using 'button-content' slot -->
                        <template slot="button-content"
                            ><span v-if="currentUser"
                                >{{ currentUser.descriptive_name
                                }}<small class="text-muted" v-if="isSUDO"
                                    >(sudo)</small
                                ></span
                            >
                        </template>
                        <BDropdownItem
                            :to="{
                                name: 'login.account',
                                params: { id: currentUser.id },
                            }"
                            v-if="currentUser"
                            >Profile</BDropdownItem
                        >
                        <BDropdownItem @click="undoSudo" v-if="isSUDO"
                            >UnSUDO</BDropdownItem
                        >

                        <BDropdownItem
                            :href="admin_link"
                            v-if="
                                currentUser &&
                                currentUser.is_staff &&
                                !hideAdmin
                            "
                            >Admin Dashboard</BDropdownItem
                        >
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem
                            :to="{ name: 'logout' }"
                            v-show="isLoggedIn"
                            >Log Out</BDropdownItem
                        >
                    </BNavItemDropdown>

                    <slot name="right-hand-side"></slot>
                </BNavbarNav>
            </BCollapse>
        </BNavbar>
        <o-side-bar
            :items="items"
            v-if="showSideBar"
            @hide_sidebar="showSideBar = !showSideBar"
        ></o-side-bar>
    </div>
</template>

<script>
import {
    BButton,
    BCollapse,
    BDropdownDivider,
    BDropdownItem,
    BNavbar,
    BNavbarBrand,
    BNavbarNav,
    BNavbarToggle,
    BNavItem,
    BNavItemDropdown,
} from "bootstrap-vue";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import HomeIcon from "vue-material-design-icons/Home.vue";
import OSideBar from "./OSideBar.vue";
import HamburgerIcon from "vue-material-design-icons/Hamburger.vue";
import MenuMixin from "./MenuMixin.js";
import { undo_sudo } from "../../../auth/store/actions.js";
import * as CONFIG from "@/config.js";

export default {
    components: {
        BNavbar,
        BNavbarNav,
        BNavItem,
        BNavbarBrand,
        BNavbarToggle,
        BCollapse,
        BNavItemDropdown,
        BDropdownItem,
        BButton,
        BDropdownDivider,
        HamburgerIcon,
        OSideBar,
        HomeIcon,
    },
    mixins: [MenuMixin],
    props: {
        items: {},
        admin_items: {},
        appName: {
            type: String,
            default: "Default App",
        },
        hideAdmin: { type: Boolean, default: false },
        hideHome: { type: Boolean, default: false },
        hideTopItems: {
            /* Hide the items on the navbar is using toolbar too */ type: Boolean,
            default: false,
        },
        toolbar: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data: () => ({
        showSideBar: false,
        admin_link: CONFIG.root_url + "/admin",
        toolbarButtonStyle: "",
    }),
    methods: {
        ...mapActions("AuthModule", ["undo_sudo"]),
        onClick(url) {
            if (url.startsWith("http")) {
                window.location.href = url;
            } else {
                this.$router.push(url);
            }
        },
        undoSudo() {
            this.undo_sudo();
        },

        showNavItem(menuItem) {
            if (_.has(menuItem, "showOnNav")) {
                if (menuItem.showOnNav === false) {
                    return false;
                }
            }
            return true;
        },
    },
    computed: {
        ...mapGetters("AuthModule", ["isLoggedIn", "currentUser", "isSUDO"]),
    },
    watch: {},
    created() {
        if (screen.width <= 760) {
            // Mobile
            this.toolbarButtonStyle = "height:80px";
        } else {
            this.toolbarButtonStyle = "height:40px";
        }
    },
};
</script>
