<template>
    <div style="display: contents">
        <select
            :value="special_param_value"
            @change="setSpecial($event)"
            class="custom-select"
            v-if="the_special_options.length > 1"
        >
            <option
                :key="op.code"
                v-bind:value="op.code"
                v-for="op in the_special_options"
            >
                {{ op.name }}
            </option>
        </select>
        <div v-if="!isSpecial(value[paramIndex + 2])">
            <!-- Boolean -->
            <BFormCheckbox
                class="mx-3"
                v-if="valueType === 'Boolean'"
                v-model="value[paramIndex + 2]"
            />
            <!-- Integer-->
            <input
                class="form-control"
                step="1"
                type="number"
                v-if="
                    valueType === 'Number' ||
                    valueType === 'AutoNumber' ||
                    valueType === 'Integer'
                "
                v-model="value[paramIndex + 2]"
            />

            <!-- Decimal-->
            <input
                class="form-control"
                step="0.01"
                type="number"
                v-if="valueType === 'Decimal'"
                v-model="value[paramIndex + 2]"
            />

            <!-- Text -->
            <input
                class="form-control"
                type="text"
                v-if="valueType === 'Text'"
                v-model="value[paramIndex + 2]"
            />

            <!-- Date -->
            <input
                class="form-control"
                type="date"
                v-if="valueType === 'Date'"
                v-model="value[paramIndex + 2]"
            />

            <!-- DateTime -->
            <input
                class="form-control"
                type="datetime"
                v-if="valueType === 'DateTime'"
                v-model="value[paramIndex + 2]"
            />

            <!-- ForeignKey -->
            <BFormSelect
                :options="the_options"
                :text-field="select_options.textField"
                :value-field="select_options.valueField"
                v-if="valueType === 'ForeignKey'"
                v-model="value[paramIndex + 2]"
            ></BFormSelect>
        </div>
    </div>
</template>
<script>
import { BFormCheckbox, BFormSelect } from "bootstrap-vue";
import _ from "lodash";
import { QUERY_SPECIAL_CODES } from "../../special_codes.js";

/*
        This component creates the "inputs" for a QueryField and TableQueryField
     */

export default {
    components: {
        BFormCheckbox,
        BFormSelect,
    },
    name: "QueryValueInput",
    props: {
        paramIndex: { type: Number },
        fieldDef: { type: Object },
        specialOptions: { type: Object, default: () => QUERY_SPECIAL_CODES },

        value: {},
        valueType: {},
        querySelectOptions: {
            type: Object,
            default: () => {},
        },
        allowNullSelectOption: { type: Boolean, default: false },
        nullSelectDescription: { type: String, default: "" },
    },
    methods: {
        isSpecial(field) {
            return field && _.isObject(field) && "special" in field;
        },
        setSpecial($event) {
            const value = $event.target.value;
            if (value == null || value === "") {
                this.$set(this.value, this.paramIndex + 2, null);
            } else {
                this.$set(this.value, this.paramIndex + 2, { special: value });
            }
        },
        get_param_special(field) {
            if (this.isSpecial(field)) {
                return field.special;
            } else return null; // value only
        },
        getSpecialOptions(type) {
            return this.specialOptions[type] || [{ name: "Value", code: null }];
        },
    },
    computed: {
        select_options() {
            if (this.fieldDef) {
                return (
                    this.querySelectOptions[this.fieldDef.ref_field_type] || {}
                );
            } else {
                return {};
            }
        },
        the_options() {
            var result = [];
            if (this.allowNullSelectOption) {
                result = [
                    {
                        [this.select_options.valueField]: null,
                        [this.select_options.textField]: this
                            .nullSelectDescription,
                    },
                    ...this.select_options.options,
                ];
            }

            result = this.select_options.options;
            // If Field in schema has "ref_filter" then filter the list on that
            if (this.fieldDef && this.fieldDef.ref_filter) {
                result = _.filter(result, this.fieldDef.ref_filter);
            }
            return result;
        },
        the_special_options() {
            return this.getSpecialOptions(this.valueType);
        },
        special_param_value() {
            return this.get_param_special(this.value[this.paramIndex + 2]);
        },
    },
};
</script>
<style scoped>
input[type="checkbox"] {
    margin-left: 1em;
}
</style>
